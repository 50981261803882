<template>
  <div class="audit-wrapper">
    <van-form>
      <van-field
        v-if="currentType === 1"
        v-model="dataList.teacherName"
        label="班主任"
        input-align="right"
        right-icon="arrow"
        required
        placeholder="请选择"
        readonly
        clickable
        @click="openTeacherList"
      />
      <van-field
        v-model="dataList.name"
        label="是否申请优惠"
        input-align="right"
        required
        readonly
      >
        <template #input>
          <div>
            <my-status-list
              :statusList="statusLists"
              @getStatusList="getExamType"
              :checkedData="dataList.isDiscount"
            ></my-status-list>
          </div>
        </template>
      </van-field>
      <van-field
        v-if="dataList.isDiscount === '1'"
        v-model="dataList.discountMoney"
        label="优惠后价格"
        type="number"
        :disabled="disabled"
        @input="changeMoney(dataList.discountMoney)"
        input-align="right"
        required
        placeholder="请输入"
      />
      <van-field
        v-if="dataList.isDiscount === '1'"
        v-model="dataList.discountReason"
        label="优惠说明"
        input-align="right"
        required
        placeholder="请输入"
      />
      <van-field
        v-if="dataList.isDiscount === '1'"
        v-model="dataList.auditorName"
        label="审批人"
        input-align="right"
        right-icon="arrow"
        required
        readonly
        clickable
        @click="isShowAuditor = true"
        placeholder="请选择"
      />
    </van-form>
    <van-popup v-model:show="isShowTeacher" position="bottom" :style="{ height: '60%' }">
      <div class="tree-wrapper">
        <my-tree
          v-if="isShowTeacher"
          :departmentLists="teacherList"
          :currentId="dataList.orderHeadTeacher"
        ></my-tree>
      </div>
    </van-popup>
    <van-popup v-model:show="isShowAuditor" position="bottom" :style="{ height: '60%' }">
      <div class="tree-wrapper">
        <my-tree
          v-if="isShowAuditor"
          :departmentLists="auditList"
          :currentId="dataList.discountReviewer"
        ></my-tree>
      </div>
    </van-popup>
  </div>
</template>
<script>
import { Form, Field, Popup } from "vant";
import { reactive, toRefs, computed, watch } from "vue";
import myStatusList from "@/components/statusList";
import myTree from "@/components/myTree";
import { getDepartmentList, getAuditList } from "@/api/common";
import { useStore } from "vuex";
export default {
  props: {
    currentType: {
      // 1 app订单 2 考籍
      type: Number,
      default: 1,
    },
    maxMoney: {
      type: Number,
      default: 0,
    },
    disabled: {
      type: Boolean,
      default: true,
    },
  },
  components: {
    myStatusList,
    myTree,
    [Form.name]: Form,
    [Field.name]: Field,
    [Popup.name]: Popup,
  },
  setup(props, context) {
    const state = reactive({
      dataList: {
        // 字段值
        isDiscount: "2",
      },
      teacherList: {}, // 班主任弹窗值
      auditList: {}, // 审批人弹窗值
    });
    const modelStatus = reactive({
      isShowTeacher: false,
      isShowAuditor: false,
    });
    const statusLists = [
      {
        type: "1",
        value: "是",
      },
      {
        type: "2",
        value: "否",
      },
    ];
    const store = useStore();
    const currentPersonal = computed(() => {
      return store.state.client.currentPersonal;
    });
    const maxMoneys = computed(() => {
      return props.maxMoney;
    });
    watch(maxMoneys, () => {
      state.dataList.discountMoney = "";
    });
    // 重置vuex是否选择状态值
    store.commit("client/setCurrentDiscountsType", 2);
    store.commit("client/setCurrentAuditMessage", null);

    watch(currentPersonal, (val) => {
      if (modelStatus.isShowTeacher) {
        state.dataList.teacherName = `${val.name}【${val.roleName}】`;
        state.dataList.orderHeadTeacher = val.userRoleId;
        modelStatus.isShowTeacher = false;
      } else if (modelStatus.isShowAuditor) {
        state.dataList.auditorName = `${val.name}【${val.roleName}】`;
        state.dataList.discountReviewer = val.userRoleId;
        const currentMessage = {
          name: state.dataList.auditorName,
          id: state.dataList.discountReviewer,
        };
        store.commit("client/setCurrentAuditMessage", currentMessage);
        modelStatus.isShowAuditor = false;
      }
    });
    const getDepartmentLists = () => {
      getDepartmentList().then((res) => {
        if (res && res.body) {
          state.teacherList = res.body;
        }
      });
    };
    // const getCompanyLists = () => {
    //   getCompanyList().then((res) => {
    //     if (res && res.body) {
    //       state.auditList = res.body;
    //     }
    //   });
    // };
    const getAuditLists = () => {
      getAuditList().then((res) => {
        if (res && res.body) {
          state.auditList = res.body;
          console.log(res);
        }
      });
    };
    getAuditLists();
    // getCompanyLists();
    getDepartmentLists();
    const openTeacherList = () => {
      modelStatus.isShowTeacher = true;
    };
    const getExamType = (item) => {
      state.dataList.isDiscount = item.type;
      store.commit("client/setCurrentDiscountsType", Number(item.type));
    };
    const clearForm = () => {
      state.dataList = {
        isDiscount: "2",
      };
      state.teacherList = {};
      state.auditList = {};
    };
    const returnCurrentParams = () => {
      return {
        orderHeadTeacher: state.dataList.orderHeadTeacher || "",
        discountReviewer: state.dataList.discountReviewer,
        discountMoney: state.dataList.discountMoney,
        discountReason: state.dataList.discountReason,
        isDiscount: Number(state.dataList.isDiscount),
      };
    };
    const changeMoney = (val) => {
      if (Number(val) >= props.maxMoney) {
        state.dataList.discountMoney = props.maxMoney;
      } else if (Number(val) < 0) {
        state.dataList.discountMoney = 0;
      } else if (val === "-") {
        state.dataList.discountMoney = 0;
      }
    };
    watch(
      state.dataList,
      (val) => {
        if (val.isDiscount === "2") {
          // 有班主任必填
          if (props.currentType === 1) {
            // app需要选择班主任
            if (val.orderHeadTeacher) {
              context.emit("hasDisabled", true);
            } else {
              context.emit("hasDisabled", false);
            }
          } else {
            context.emit("hasDisabled", true);
          }
        } else {
          if (props.currentType === 1) {
            if (
              val.orderHeadTeacher &&
              val.discountReviewer &&
              val.discountReason &&
              val.discountMoney
            ) {
              context.emit("hasDisabled", true);
            } else {
              context.emit("hasDisabled", false);
            }
          } else {
            console.log(val);
            if (val.discountReviewer && val.discountReason && val.discountMoney) {
              context.emit("hasDisabled", true);
            } else {
              context.emit("hasDisabled", false);
            }
          }
        }
      },
      { immediate: true, deep: true }
    );
    return {
      statusLists,
      openTeacherList,
      getExamType,
      clearForm,
      changeMoney,
      returnCurrentParams,
      ...toRefs(modelStatus),
      ...toRefs(state),
    };
  },
};
</script>
